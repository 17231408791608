interface ILogo {
  isLight?: boolean;
}

const LogoLanding = ({ isLight = true }: ILogo) => {
  const TMColor = isLight ? "white" : "#124680";
  const descColor = isLight ? "white" : "#4A4A4A";
  return (
    <svg
      width="140"
      height="39"
      viewBox="0 0 140 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.50765 10.3115V17.8732C6.50765 18.5607 6.73679 18.7898 6.87427 18.8815C7.14924 19.0189 7.47004 19.1106 7.8825 19.1106C8.2033 19.1106 9.1657 18.8356 9.71564 18.6065L10.3114 18.4232V21.3104L10.0823 21.4479C9.25735 21.8603 8.38661 22.0895 7.47004 22.0895C3.89542 22.0895 3.11634 19.9355 3.11634 18.1024V10.3115H0L6.50765 3.75806V7.33268H10.3572V10.3115H6.50765Z"
        fill={TMColor}
      />
      <path
        d="M21.0808 7.05762V10.2656H20.3934C19.431 10.2656 18.5144 10.4489 17.6437 10.8156C16.8646 11.1822 16.2689 11.6405 15.8106 12.2362V21.7227H12.4651V7.33259H14.894L15.4898 9.07407C16.773 7.74504 18.4228 7.10345 20.3476 7.10345H21.0808V7.05762Z"
        fill={TMColor}
      />
      <path
        d="M26.3506 3.57473C26.3506 4.53713 25.5716 5.31622 24.6092 5.31622C23.6468 5.31622 22.8677 4.53713 22.8677 3.57473C22.8677 2.61234 23.6468 1.83325 24.6092 1.83325C25.5257 1.83325 26.3506 2.61234 26.3506 3.57473Z"
        fill={TMColor}
      />
      <path
        d="M26.2132 7.33252H22.8677V21.8143H26.2132V7.33252Z"
        fill={TMColor}
      />
      <path
        d="M41.8863 9.074C40.5114 7.69915 38.9074 7.01172 37.0743 7.01172C35.2412 7.01172 33.4997 8.1116 32.7206 8.70737L32.0332 7.33252H29.6042V27.9095H32.9497V20.6228C34.1413 21.5852 35.5161 22.0893 37.0285 22.0893C38.8158 22.0893 40.3739 21.4477 41.703 20.1645L41.8405 20.027C43.2153 18.6522 43.9027 16.819 43.9027 14.5734C43.9486 12.282 43.2611 10.4489 41.8863 9.074ZM36.7077 18.8813C35.287 18.8813 34.0496 18.3314 32.9497 17.1856V11.9154C34.0496 10.7697 35.2412 10.2197 36.7077 10.2197C37.8992 10.2197 38.8158 10.5863 39.549 11.3196C40.2823 12.0529 40.6031 13.1527 40.6031 14.5276C40.6031 15.9024 40.2365 17.0023 39.549 17.7356C38.8158 18.5147 37.8992 18.8813 36.7077 18.8813Z"
        fill={TMColor}
      />
      <path
        d="M90.3266 9.1199C88.9976 7.74504 87.2103 7.05762 85.0563 7.05762C82.9024 7.05762 81.1151 7.74504 79.7861 9.1199C78.457 10.4948 77.8154 12.3279 77.8154 14.5277C77.8154 16.7733 78.5029 18.5606 79.7861 19.9354C81.1151 21.3103 82.9024 21.9977 85.0563 21.9977C87.2103 21.9977 88.9976 21.3103 90.3266 19.9354C91.6556 18.5606 92.3431 16.7274 92.3431 14.5277C92.2972 12.3279 91.6098 10.4948 90.3266 9.1199ZM85.0563 18.9272C84.0023 18.9272 83.0857 18.5606 82.3066 17.8273C81.5275 17.1399 81.1151 15.9942 81.1151 14.5277C81.1151 13.0612 81.5275 11.9613 82.2608 11.228C83.0399 10.4948 83.9565 10.1281 85.0105 10.1281C86.0646 10.1281 86.9811 10.4948 87.7602 11.228C88.5393 11.9154 88.9059 13.0153 88.9059 14.5277C88.9059 15.9942 88.4935 17.1399 87.7602 17.8273C87.027 18.5606 86.1104 18.9272 85.0563 18.9272Z"
        fill={TMColor}
      />
      <path
        d="M107.788 12.6945V21.7686H104.443V12.9695C104.443 11.0447 103.664 10.174 101.968 10.174C100.593 10.174 99.3099 10.953 98.0725 12.4196V21.7686H94.7271V7.33259H97.156L97.7976 9.07407C99.2182 7.74504 100.776 7.05762 102.564 7.05762C104.168 7.05762 105.451 7.60756 106.413 8.70744C107.33 9.7615 107.788 11.1364 107.788 12.6945Z"
        fill={TMColor}
      />
      <path
        d="M122.132 17.5981C120.849 18.423 119.474 18.8355 118.008 18.8355C116.541 18.8355 115.441 18.5147 114.754 17.9189C114.158 17.4148 113.791 16.7274 113.608 15.8108H123.782V14.0235C123.69 12.1445 123.14 10.5405 122.086 9.21149C120.941 7.74497 119.199 7.01172 116.999 7.01172C114.8 7.01172 113.104 7.74497 111.912 9.21149C110.767 10.678 110.171 12.5111 110.171 14.7109C110.171 16.9107 110.812 18.698 112.142 20.027C113.425 21.356 115.166 21.9976 117.32 21.9976C119.382 21.9976 121.261 21.5394 122.82 20.6686L123.003 20.6228L122.82 17.1856L122.132 17.5981ZM113.562 12.9694C113.7 12.0529 114.066 11.3654 114.616 10.9071C115.304 10.3114 116.083 10.0364 116.954 10.0364C117.824 10.0364 118.603 10.3114 119.291 10.9071C119.887 11.4113 120.253 12.0987 120.345 12.9694H113.562Z"
        fill={TMColor}
      />
      <path
        d="M139.18 7.33252L138.951 7.92829L130.656 27.9095H127.219L129.694 21.5394L123.919 7.33252H127.494L131.481 17.369L135.606 7.33252H139.18Z"
        fill={TMColor}
      />
      <path
        d="M46.2869 7.43108C46.2869 2.92739 49.2143 0 53.7179 0H68.186C72.6897 0 75.6171 2.92739 75.6171 7.43108V21.8992C75.6171 26.4028 72.6897 29.3302 68.186 29.3302H53.7179C49.2143 29.3302 46.2869 26.4028 46.2869 21.8992V7.43108Z"
        fill="#ED1C24"
      />
      <mask
        id="mask0_320_367"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="46"
        y="0"
        width="30"
        height="30"
      >
        <path
          d="M46.2869 7.43108C46.2869 2.92739 49.2143 0 53.7179 0H68.186C72.6897 0 75.6171 2.92739 75.6171 7.43108V21.8992C75.6171 26.4028 72.6897 29.3302 68.186 29.3302H53.7179C49.2143 29.3302 46.2869 26.4028 46.2869 21.8992V7.43108Z"
          fill="#ED1C24"
        />
      </mask>
      <g mask="url(#mask0_320_367)">
        <path
          d="M66.792 14.4447C66.5087 15.7452 66.2615 19.2469 66.2282 20.0863C66.195 20.9256 66.4679 21.4408 67.1387 21.7729C67.7127 22.0571 68.2541 22.0497 68.7761 21.6786C68.9718 21.5394 68.6733 19.3188 68.7761 17.8125C68.9897 14.6821 70.4517 9.9545 70.4517 9.9545L70.456 9.92934C70.4821 9.77505 70.5079 9.62221 70.5079 9.49359C70.5079 8.6807 70.1103 8.16593 69.2591 8.08467C68.38 8.00316 67.925 8.40997 67.1312 9.33106C65.3707 11.4173 63.2703 14.5338 62.2774 15.9703C62.1646 16.1319 61.9654 16.0783 61.9654 15.8614C61.9654 15.2113 64.3311 8.27607 64.3311 8.27607C64.3311 8.27607 64.6095 7.32586 63.7978 6.99692C63.7978 6.99692 62.579 6.26529 61.5451 7.7157L56.7457 13.9816C56.3689 14.4612 56.1819 14.2837 56.1819 14.2837C56.0647 14.2266 56.1402 13.8693 56.1402 13.8693L56.8971 9.78469C57.5028 6.64445 56.3488 6.47925 55.6275 6.38658C54.9064 6.29391 54.0408 6.81232 54.0408 6.81232C52.5405 7.53133 51.4083 10.3921 51.4083 10.3921C51.4083 10.3921 51.0691 11.41 51.7838 11.8025C51.7838 11.8025 52.0554 12.0179 52.5826 11.9995C53.6305 11.9626 53.8484 10.0554 54.6147 10.0554C54.7573 10.0554 54.7573 10.2179 54.7573 10.2451L52.2788 20.2032C52.2788 20.2032 51.9947 21.1893 52.8985 21.7169C54.054 22.3913 54.8022 21.2703 54.8022 21.2703L55.6689 19.9327C56.9924 17.7194 59.3908 14.5466 59.6459 14.5466C59.7313 14.5466 59.7592 14.5745 59.7592 14.6546L58.9119 20.4902C58.9119 20.4902 58.729 21.4561 59.6238 21.7729C59.6238 21.7729 60.4971 22.0653 60.9996 21.6786C60.9996 21.6786 61.6252 21.2739 62.2487 20.2975C62.7294 19.5446 65.1467 16.8284 66.6218 14.3361C66.7359 14.1733 66.8207 14.3086 66.792 14.4447Z"
          fill="white"
        />
      </g>
      <path
        d="M32.4 37.3045L31.7584 35.7464H29.7877L29.192 37.3045H28.5962L30.521 32.4009H31.0251L32.9499 37.3045H32.4ZM31.6209 35.2422L31.0251 33.6841C31.0251 33.6382 30.9793 33.5924 30.9793 33.5008C30.9334 33.4091 30.9334 33.3175 30.8876 33.2258C30.8418 33.1341 30.8418 33.0425 30.796 32.9967C30.796 33.0883 30.7501 33.18 30.7043 33.2716C30.6585 33.3633 30.6585 33.4549 30.6126 33.5008C30.5668 33.5924 30.5668 33.6382 30.5668 33.6841L29.971 35.2422H31.6209Z"
        fill={descColor}
      />
      <path
        d="M37.6702 37.3045L36.0204 32.9508H35.9746C35.9746 33.0425 35.9746 33.1341 36.0204 33.2716C36.0204 33.4091 36.0204 33.5466 36.0204 33.6841C36.0204 33.8216 36.0204 34.0049 36.0204 34.1424V37.2587H35.4705V32.4009H36.3412L37.8994 36.4796H37.9452L39.5492 32.4009H40.3741V37.3045H39.8242V34.1424C39.8242 34.0049 39.8242 33.8674 39.8242 33.6841C39.8242 33.5466 39.8242 33.4091 39.8242 33.2716C39.8242 33.1341 39.8242 33.0425 39.8242 32.9508H39.7783L38.0827 37.3045H37.6702Z"
        fill={descColor}
      />
      <path
        d="M43.0314 33.5466C43.4897 33.5466 43.8105 33.6383 44.0396 33.8216C44.2688 34.0049 44.3604 34.3257 44.3604 34.784V37.3046H43.948L43.8563 36.7546H43.8105C43.7188 36.8921 43.5814 36.9838 43.4897 37.0754C43.398 37.1671 43.2606 37.2129 43.0772 37.2587C42.9398 37.3046 42.7564 37.3046 42.5273 37.3046C42.2982 37.3046 42.1148 37.2587 41.9315 37.1671C41.7482 37.0754 41.6107 36.9838 41.5191 36.8005C41.4274 36.663 41.3816 36.4338 41.3816 36.2047C41.3816 35.8381 41.5191 35.5631 41.8399 35.3798C42.1148 35.1965 42.5731 35.059 43.1689 35.059L43.8105 35.0131V34.784C43.8105 34.4632 43.7188 34.2341 43.5814 34.0966C43.4439 33.9591 43.2606 33.9133 42.9856 33.9133C42.8023 33.9133 42.619 33.9591 42.4356 34.0049C42.2523 34.0507 42.1148 34.1424 41.9315 34.1882L41.7482 33.7758C41.9315 33.6841 42.1148 33.6383 42.344 33.5466C42.5731 33.5925 42.8023 33.5466 43.0314 33.5466ZM43.7647 35.4714L43.2147 35.5173C42.7564 35.5173 42.4356 35.6089 42.2523 35.7464C42.069 35.8839 41.9774 36.0672 41.9774 36.2963C41.9774 36.5255 42.0232 36.663 42.1607 36.7546C42.2982 36.8463 42.4815 36.8921 42.6648 36.8921C42.9856 36.8921 43.2606 36.8005 43.4897 36.6171C43.7188 36.4338 43.8105 36.1589 43.8105 35.7922V35.4714H43.7647Z"
        fill={descColor}
      />
      <path
        d="M46.056 32.0798V34.7837C46.056 34.8754 46.056 34.967 46.056 35.1045C46.056 35.242 46.056 35.3337 46.056 35.4253H46.1019C46.1477 35.3795 46.2394 35.2878 46.331 35.1503C46.4227 35.0587 46.5143 34.967 46.5602 34.8754L47.7975 33.5922H48.4391L46.9268 35.1503L48.5308 37.2585H47.8434L46.5143 35.517L46.056 35.9294V37.2585H45.5061V32.0798H46.056Z"
        fill={descColor}
      />
      <path
        d="M50.594 33.5466C50.9148 33.5466 51.1898 33.6383 51.4189 33.7758C51.648 33.9133 51.8314 34.0966 51.923 34.3715C52.0605 34.6007 52.1063 34.9215 52.1063 35.2423V35.6089H49.5399C49.5399 36.0214 49.6774 36.388 49.8607 36.6171C50.0899 36.8463 50.3648 36.9379 50.7315 36.9379C50.9606 36.9379 51.1898 36.9379 51.3731 36.8921C51.5564 36.8463 51.7397 36.8005 51.923 36.7088V37.1213C51.7397 37.2129 51.5564 37.2587 51.3731 37.3046C51.1898 37.3504 50.9606 37.3504 50.7315 37.3504C50.3648 37.3504 50.044 37.2587 49.8149 37.1213C49.5399 36.9838 49.3566 36.7546 49.2191 36.4797C49.0816 36.2047 48.99 35.8381 48.99 35.4714C48.99 35.059 49.0358 34.7382 49.1733 34.4632C49.3108 34.1882 49.4941 33.9591 49.7232 33.7758C49.9524 33.6383 50.2274 33.5466 50.594 33.5466ZM50.5482 34.0049C50.2274 34.0049 49.9982 34.0966 49.8149 34.2799C49.6316 34.4632 49.5399 34.7382 49.4941 35.1048H51.4647C51.4647 34.8757 51.4189 34.6923 51.3731 34.509C51.3272 34.3257 51.1898 34.2341 51.0523 34.1424C50.9606 34.0507 50.7773 34.0049 50.5482 34.0049Z"
        fill={descColor}
      />
      <path
        d="M55.3143 37.3045L53.6644 32.9508H53.6186C53.6186 33.0425 53.6186 33.1341 53.6644 33.2716C53.6644 33.4091 53.6644 33.5466 53.6644 33.6841C53.6644 33.8216 53.6644 34.0049 53.6644 34.1424V37.2587H53.1145V32.4009H53.9852L55.5434 36.4796H55.5892L57.1932 32.4009H58.0181V37.3045H57.4682V34.1424C57.4682 34.0049 57.4682 33.8674 57.4682 33.6841C57.4682 33.5466 57.4682 33.4091 57.4682 33.2716C57.4682 33.1341 57.4682 33.0425 57.4682 32.9508L55.7726 37.3045H55.3143Z"
        fill={descColor}
      />
      <path
        d="M58.7051 33.6382H59.3008L60.1258 35.7921C60.1716 35.9296 60.2174 36.0213 60.2632 36.1588C60.3091 36.2504 60.3549 36.3879 60.3549 36.4795C60.3549 36.5712 60.4007 36.7087 60.4466 36.8004H60.4924C60.5382 36.7087 60.584 36.5254 60.6299 36.3421C60.6757 36.1588 60.7674 35.9754 60.8132 35.7921L61.5923 33.684H62.188L60.584 37.9002C60.4924 38.1294 60.4007 38.3127 60.2632 38.496C60.1716 38.6793 60.0341 38.771 59.8508 38.8626C59.6675 38.9543 59.4842 39.0001 59.255 39.0001C59.1634 39.0001 59.0717 39.0001 58.9801 39.0001C58.8884 39.0001 58.8426 38.9543 58.7509 38.9543V38.496C58.7967 38.496 58.8426 38.496 58.9342 38.5418C59.0259 38.5418 59.0717 38.5418 59.1634 38.5418C59.3008 38.5418 59.4383 38.496 59.53 38.4502C59.6216 38.4043 59.7133 38.3127 59.805 38.221C59.8966 38.1294 59.9424 37.9919 59.9883 37.8544L60.1716 37.3503L58.7051 33.6382Z"
        fill={descColor}
      />
      <path
        d="M64.3876 37.3045H63.7918V32.905H62.2336V32.4009H65.9457V32.905H64.3876V37.3045Z"
        fill={descColor}
      />
      <path
        d="M68.2831 33.5466C68.3747 33.5466 68.4205 33.5466 68.5122 33.5466C68.6039 33.5466 68.6497 33.5466 68.7413 33.5925L68.6497 34.0966C68.6039 34.0966 68.5122 34.0507 68.4664 34.0507C68.3747 34.0507 68.3289 34.0507 68.2372 34.0507C68.0997 34.0507 67.9623 34.0966 67.8248 34.1424C67.6873 34.1882 67.5956 34.2799 67.4581 34.4174C67.3665 34.509 67.2748 34.6465 67.229 34.8298C67.1832 34.9673 67.1373 35.1506 67.1373 35.3339V37.3046H66.5874V33.6383H67.0457L67.0915 34.3257H67.1373C67.229 34.1882 67.3207 34.0507 67.4123 33.9591C67.504 33.8674 67.6415 33.7758 67.7789 33.6841C67.9623 33.5925 68.0997 33.5466 68.2831 33.5466Z"
        fill={descColor}
      />
      <path
        d="M69.9793 33.638V37.3043H69.4294V33.638H69.9793ZM69.7043 32.2632C69.796 32.2632 69.8877 32.309 69.9335 32.3548C69.9793 32.4007 70.0251 32.4923 70.0251 32.6298C70.0251 32.7673 69.9793 32.859 69.9335 32.9048C69.8877 32.9506 69.796 32.9964 69.7043 32.9964C69.6127 32.9964 69.521 32.9506 69.4752 32.9048C69.4294 32.859 69.3835 32.7673 69.3835 32.6298C69.3835 32.4923 69.4294 32.4007 69.4752 32.3548C69.5669 32.2632 69.6127 32.2632 69.7043 32.2632Z"
        fill={descColor}
      />
      <path
        d="M72.9583 33.5466C73.4166 33.5466 73.7832 33.6841 74.0582 34.0049C74.3331 34.3257 74.4706 34.784 74.4706 35.4256C74.4706 35.8381 74.4248 36.2047 74.2873 36.4797C74.1498 36.7546 73.9665 36.9838 73.7374 37.1213C73.5082 37.2587 73.2332 37.3504 72.9124 37.3504C72.7291 37.3504 72.5458 37.3046 72.4083 37.2587C72.2709 37.2129 72.1334 37.1213 72.0417 37.0296C71.9501 36.9379 71.8584 36.8463 71.7667 36.7546H71.7209C71.7209 36.8463 71.7209 36.9379 71.7667 37.0754C71.7667 37.2129 71.7667 37.3046 71.7667 37.3962V38.9544H71.2168V33.6383H71.6751L71.7667 34.1882H71.8126C71.9042 34.0966 71.95 33.9591 72.0875 33.8674C72.1792 33.7758 72.3167 33.6841 72.4542 33.6383C72.5458 33.5925 72.7291 33.5466 72.9583 33.5466ZM72.8208 34.0049C72.5458 34.0049 72.3625 34.0507 72.1792 34.1424C72.0417 34.2341 71.9042 34.3715 71.8584 34.6007C71.7667 34.784 71.7667 35.059 71.7209 35.3339V35.4256C71.7209 35.7464 71.7667 36.0214 71.8126 36.2047C71.9042 36.4338 71.9959 36.5713 72.1334 36.7088C72.3167 36.8005 72.5 36.8921 72.775 36.8921C73.0041 36.8921 73.1874 36.8463 73.3707 36.7088C73.5082 36.5713 73.6457 36.4338 73.6915 36.2047C73.7832 35.9755 73.829 35.7464 73.829 35.4256C73.829 35.0131 73.7374 34.6465 73.5541 34.3715C73.4624 34.1424 73.1874 34.0049 72.8208 34.0049Z"
        fill={descColor}
      />
      <path
        d="M79.4661 34.738H81.2076V37.1211C80.9326 37.2127 80.6576 37.2586 80.3827 37.3044C80.1077 37.3502 79.7869 37.3502 79.4661 37.3502C78.962 37.3502 78.5037 37.2586 78.1829 37.0294C77.8163 36.8461 77.5413 36.5253 77.358 36.1587C77.1747 35.792 77.083 35.3338 77.083 34.8296C77.083 34.3255 77.1747 33.8672 77.4038 33.5006C77.5871 33.134 77.9079 32.8132 78.2745 32.6299C78.6412 32.4007 79.0995 32.3091 79.6494 32.3091C79.9244 32.3091 80.1993 32.3549 80.4285 32.4007C80.6576 32.4466 80.8868 32.5382 81.1159 32.6299L80.8868 33.134C80.7035 33.0423 80.5201 32.9965 80.291 32.9507C80.0619 32.9049 79.8327 32.859 79.6036 32.859C79.1911 32.859 78.8703 32.9507 78.5495 33.0882C78.2745 33.2715 78.0454 33.5006 77.9079 33.7756C77.7704 34.0964 77.6788 34.4172 77.6788 34.8296C77.6788 35.2421 77.7246 35.6087 77.8621 35.8837C77.9996 36.2045 78.1829 36.4336 78.5037 36.5711C78.7787 36.7544 79.1453 36.8003 79.6036 36.8003C79.8327 36.8003 80.016 36.8003 80.1993 36.7544C80.3827 36.7086 80.5201 36.7086 80.6576 36.6628V35.1504H79.4661V34.738Z"
        fill={descColor}
      />
      <path
        d="M84.0025 33.5466C84.0942 33.5466 84.14 33.5466 84.2317 33.5466C84.3233 33.5466 84.3692 33.5466 84.4608 33.5925L84.3692 34.0966C84.3233 34.0966 84.2317 34.0507 84.1859 34.0507C84.0942 34.0507 84.0484 34.0507 83.9567 34.0507C83.8192 34.0507 83.6817 34.0966 83.5443 34.1424C83.4068 34.1882 83.3151 34.2799 83.1776 34.4174C83.086 34.509 82.9943 34.6465 82.9485 34.8298C82.9027 34.9673 82.8568 35.1506 82.8568 35.3339V37.3046H82.3069V33.6383H82.7652L82.811 34.3257H82.8568C82.9485 34.1882 83.0401 34.0507 83.1318 33.9591C83.2235 33.8674 83.3609 33.7758 83.4984 33.6841C83.6359 33.5925 83.8192 33.5466 84.0025 33.5466Z"
        fill={descColor}
      />
      <path
        d="M88.357 35.4713C88.357 35.7921 88.3112 36.0212 88.2195 36.2962C88.1278 36.5253 88.0362 36.7544 87.8987 36.8919C87.7612 37.0752 87.5779 37.1669 87.3488 37.2586C87.1196 37.3502 86.8905 37.396 86.6155 37.396C86.3864 37.396 86.1572 37.3502 85.9281 37.2586C85.7448 37.1669 85.5614 37.0294 85.3781 36.8919C85.2406 36.7086 85.1032 36.5253 85.0115 36.2962C84.9199 36.067 84.874 35.7921 84.874 35.4713C84.874 35.0588 84.9657 34.738 85.1032 34.463C85.2407 34.1881 85.424 33.9589 85.6989 33.8214C85.9739 33.6839 86.2489 33.5923 86.6155 33.5923C86.9363 33.5923 87.2571 33.6839 87.4862 33.8214C87.7612 33.9589 87.9445 34.1881 88.082 34.463C88.2653 34.6922 88.357 35.0588 88.357 35.4713ZM85.5156 35.4713C85.5156 35.7462 85.5614 36.0212 85.6531 36.2503C85.7448 36.4795 85.8364 36.617 86.0197 36.7544C86.203 36.8919 86.3864 36.9378 86.6613 36.9378C86.9363 36.9378 87.1196 36.8919 87.3029 36.7544C87.4862 36.617 87.5779 36.4795 87.6696 36.2503C87.7612 36.0212 87.807 35.7921 87.807 35.4713C87.807 35.1963 87.7612 34.9213 87.6696 34.6922C87.5779 34.463 87.4862 34.3255 87.3029 34.1881C87.1196 34.0506 86.9363 34.0047 86.6613 34.0047C86.2947 34.0047 85.9739 34.1422 85.7906 34.3714C85.6073 34.6463 85.5156 35.013 85.5156 35.4713Z"
        fill={descColor}
      />
      <path
        d="M92.3888 33.6383V37.3046H91.9305L91.8389 36.8005C91.7472 36.9379 91.6555 37.0296 91.5181 37.1213C91.3806 37.2129 91.2431 37.2587 91.1056 37.3046C90.9681 37.3504 90.7848 37.3504 90.6015 37.3504C90.3265 37.3504 90.0515 37.3046 89.8682 37.2129C89.6849 37.1213 89.5016 36.9838 89.4099 36.7546C89.3183 36.5713 89.2725 36.2963 89.2725 35.9755V33.5466H89.8224V35.9297C89.8224 36.2505 89.9141 36.4797 90.0516 36.6171C90.189 36.7546 90.4182 36.8463 90.6931 36.8463C90.9681 36.8463 91.1973 36.8005 91.3347 36.7088C91.5181 36.6171 91.6097 36.4338 91.7014 36.2505C91.793 36.0672 91.793 35.7922 91.793 35.5173V33.5925H92.3888V33.6383Z"
        fill={descColor}
      />
      <path
        d="M95.3228 33.5466C95.7811 33.5466 96.1477 33.6841 96.4227 34.0049C96.6976 34.3257 96.8351 34.784 96.8351 35.4256C96.8351 35.8381 96.7893 36.2047 96.6518 36.4797C96.5143 36.7546 96.331 36.9838 96.1019 37.1213C95.8727 37.2587 95.5978 37.3504 95.277 37.3504C95.0936 37.3504 94.9103 37.3046 94.7728 37.2587C94.6353 37.2129 94.4979 37.1213 94.4062 37.0296C94.3146 36.9379 94.2229 36.8463 94.1312 36.7546H94.0854C94.0854 36.8463 94.0854 36.9379 94.1312 37.0754C94.1312 37.2129 94.1312 37.3046 94.1312 37.3962V38.9544H93.5813V33.6383H94.0396L94.1312 34.1882H94.1771C94.2687 34.0966 94.3146 33.9591 94.452 33.8674C94.5437 33.7758 94.6812 33.6841 94.8187 33.6383C94.9562 33.5925 95.1395 33.5466 95.3228 33.5466ZM95.2311 34.0049C94.9561 34.0049 94.7728 34.0507 94.5895 34.1424C94.452 34.2341 94.3146 34.3715 94.2687 34.6007C94.1771 34.784 94.1771 35.059 94.1312 35.3339V35.4256C94.1312 35.7464 94.1771 36.0214 94.2229 36.2047C94.3146 36.4338 94.4062 36.5713 94.5437 36.7088C94.727 36.8005 94.9103 36.8921 95.1853 36.8921C95.4144 36.8921 95.5978 36.8463 95.7811 36.7088C95.9186 36.5713 96.056 36.4338 96.1019 36.2047C96.1935 35.9755 96.2394 35.7464 96.2394 35.4256C96.2394 35.0131 96.1477 34.6465 95.9644 34.3715C95.8269 34.1424 95.5977 34.0049 95.2311 34.0049Z"
        fill={descColor}
      />
      <path
        d="M101.83 32.8132C101.555 32.8132 101.326 32.859 101.096 32.9507C100.867 33.0423 100.684 33.1798 100.546 33.3631C100.409 33.5465 100.272 33.7298 100.18 34.0047C100.088 34.2339 100.042 34.5547 100.042 34.8296C100.042 35.2421 100.088 35.6087 100.226 35.8837C100.363 36.2045 100.546 36.4336 100.821 36.5711C101.096 36.7544 101.417 36.8003 101.784 36.8003C102.013 36.8003 102.196 36.8003 102.425 36.7544C102.609 36.7086 102.792 36.6628 103.021 36.617V37.1211C102.838 37.1669 102.655 37.2586 102.471 37.2586C102.288 37.3044 102.059 37.3044 101.784 37.3044C101.28 37.3044 100.867 37.2127 100.501 36.9836C100.18 36.7544 99.9049 36.4795 99.7216 36.1128C99.5383 35.7462 99.4924 35.2879 99.4924 34.7838C99.4924 34.4172 99.5383 34.0964 99.6299 33.7756C99.7216 33.4548 99.9049 33.1798 100.088 32.9965C100.272 32.7674 100.546 32.5841 100.821 32.4924C101.096 32.3549 101.463 32.3091 101.83 32.3091C102.059 32.3091 102.334 32.3549 102.563 32.4007C102.792 32.4466 103.021 32.5382 103.205 32.6299L102.975 33.134C102.838 33.0423 102.655 32.9965 102.471 32.9507C102.242 32.859 102.059 32.8132 101.83 32.8132Z"
        fill={descColor}
      />
      <path
        d="M107.238 35.4713C107.238 35.7921 107.192 36.0212 107.1 36.2962C107.009 36.5253 106.917 36.7544 106.78 36.8919C106.642 37.0752 106.459 37.1669 106.23 37.2586C106 37.3502 105.771 37.396 105.496 37.396C105.267 37.396 105.038 37.3502 104.809 37.2586C104.626 37.1669 104.442 37.0294 104.259 36.8919C104.122 36.7086 103.984 36.5253 103.892 36.2962C103.801 36.067 103.755 35.7921 103.755 35.4713C103.755 35.0588 103.847 34.738 103.984 34.463C104.122 34.1881 104.305 33.9589 104.58 33.8214C104.855 33.6839 105.13 33.5923 105.496 33.5923C105.817 33.5923 106.138 33.6839 106.367 33.8214C106.642 33.9589 106.825 34.1881 106.963 34.463C107.146 34.6922 107.238 35.0588 107.238 35.4713ZM104.396 35.4713C104.396 35.7462 104.442 36.0212 104.534 36.2503C104.626 36.4795 104.717 36.617 104.901 36.7544C105.084 36.8919 105.267 36.9378 105.542 36.9378C105.817 36.9378 106 36.8919 106.184 36.7544C106.367 36.617 106.459 36.4795 106.55 36.2503C106.642 36.0212 106.688 35.7921 106.688 35.4713C106.688 35.1963 106.642 34.9213 106.55 34.6922C106.459 34.463 106.367 34.3255 106.184 34.1881C106 34.0506 105.817 34.0047 105.542 34.0047C105.176 34.0047 104.855 34.1422 104.671 34.3714C104.488 34.6463 104.396 35.013 104.396 35.4713Z"
        fill={descColor}
      />
      <path
        d="M112.234 33.5466C112.646 33.5466 112.967 33.6383 113.15 33.8674C113.379 34.0966 113.471 34.4174 113.471 34.8757V37.3046H112.921V34.9215C112.921 34.6465 112.875 34.4174 112.738 34.2341C112.6 34.0966 112.417 34.0049 112.142 34.0049C111.775 34.0049 111.5 34.0966 111.363 34.3257C111.18 34.509 111.134 34.8298 111.134 35.2423V37.3046H110.584V34.9215C110.584 34.7382 110.538 34.5549 110.492 34.4174C110.446 34.2799 110.355 34.1882 110.217 34.1424C110.126 34.0966 109.942 34.0507 109.805 34.0507C109.576 34.0507 109.346 34.0966 109.209 34.1882C109.071 34.2799 108.934 34.4174 108.888 34.6465C108.842 34.8298 108.797 35.1048 108.797 35.3798V37.3046H108.247V33.6383H108.705L108.797 34.1424H108.842C108.934 34.0049 109.026 33.9133 109.117 33.8216C109.209 33.7299 109.346 33.6841 109.484 33.6383C109.621 33.5925 109.759 33.5925 109.942 33.5925C110.217 33.5925 110.446 33.6383 110.63 33.7299C110.813 33.8216 110.95 34.0049 111.042 34.1882H111.088C111.225 33.9591 111.363 33.8216 111.592 33.7299C111.73 33.5925 111.959 33.5466 112.234 33.5466Z"
        fill={descColor}
      />
      <path
        d="M116.357 33.5466C116.816 33.5466 117.182 33.6841 117.457 34.0049C117.732 34.3257 117.87 34.784 117.87 35.4256C117.87 35.8381 117.824 36.2047 117.686 36.4797C117.549 36.7546 117.366 36.9838 117.137 37.1213C116.907 37.2587 116.632 37.3504 116.312 37.3504C116.128 37.3504 115.945 37.3046 115.808 37.2587C115.67 37.2129 115.533 37.1213 115.441 37.0296C115.349 36.9379 115.258 36.8463 115.166 36.7546H115.12C115.12 36.8463 115.12 36.9379 115.166 37.0754C115.166 37.2129 115.166 37.3046 115.166 37.3962V38.9544H114.616V33.6383H115.074L115.166 34.1882H115.212C115.303 34.0966 115.395 33.9591 115.487 33.8674C115.578 33.7758 115.716 33.6841 115.853 33.6383C115.991 33.5925 116.174 33.5466 116.357 33.5466ZM116.266 34.0049C115.991 34.0049 115.808 34.0507 115.624 34.1424C115.487 34.2341 115.349 34.3715 115.303 34.6007C115.212 34.784 115.212 35.059 115.166 35.3339V35.4256C115.166 35.7464 115.212 36.0214 115.258 36.2047C115.349 36.4338 115.441 36.5713 115.578 36.7088C115.762 36.8005 115.945 36.8921 116.22 36.8921C116.449 36.8921 116.632 36.8463 116.816 36.7088C116.953 36.5713 117.091 36.4338 117.137 36.2047C117.228 35.9755 117.274 35.7464 117.274 35.4256C117.274 35.0131 117.182 34.6465 116.999 34.3715C116.862 34.1424 116.632 34.0049 116.266 34.0049Z"
        fill={descColor}
      />
      <path
        d="M120.252 33.5466C120.711 33.5466 121.031 33.6383 121.261 33.8216C121.49 34.0049 121.581 34.3257 121.581 34.784V37.3046H121.169L121.077 36.7546H121.031C120.94 36.8921 120.802 36.9838 120.711 37.0754C120.619 37.1671 120.482 37.2129 120.298 37.2587C120.161 37.3046 119.977 37.3046 119.748 37.3046C119.519 37.3046 119.336 37.2587 119.152 37.1671C118.969 37.0754 118.832 36.9838 118.74 36.8005C118.648 36.663 118.603 36.4338 118.603 36.2047C118.603 35.8381 118.74 35.5631 119.061 35.3798C119.336 35.1965 119.794 35.059 120.39 35.059L121.031 35.0131V34.784C121.031 34.4632 120.986 34.2341 120.802 34.0966C120.665 33.9591 120.482 33.9133 120.207 33.9133C120.023 33.9133 119.84 33.9591 119.657 34.0049C119.473 34.0507 119.29 34.1424 119.152 34.1882L118.969 33.7758C119.152 33.6841 119.336 33.6383 119.565 33.5466C119.794 33.5925 120.023 33.5466 120.252 33.5466ZM121.031 35.4714L120.482 35.5173C120.023 35.5173 119.702 35.6089 119.519 35.7464C119.336 35.8839 119.244 36.0672 119.244 36.2963C119.244 36.5255 119.29 36.663 119.427 36.7546C119.565 36.8463 119.748 36.8921 119.932 36.8921C120.252 36.8921 120.527 36.8005 120.756 36.6171C120.986 36.4338 121.077 36.1589 121.077 35.7922V35.4714H121.031Z"
        fill={descColor}
      />
      <path
        d="M124.469 33.5466C124.928 33.5466 125.248 33.6383 125.478 33.8674C125.707 34.0966 125.798 34.4174 125.798 34.8757V37.2587H125.294V34.9215C125.294 34.6007 125.203 34.3715 125.065 34.2341C124.928 34.0966 124.698 34.0049 124.424 34.0049C124.011 34.0049 123.736 34.1424 123.553 34.3715C123.369 34.6007 123.324 34.9215 123.324 35.3798V37.3046H122.774V33.6383H123.232L123.324 34.1882H123.369C123.461 34.0507 123.553 33.9591 123.69 33.8674C123.828 33.7758 123.965 33.7299 124.103 33.6841C124.149 33.5925 124.332 33.5466 124.469 33.5466Z"
        fill={descColor}
      />
      <path
        d="M126.394 33.6382H126.989L127.814 35.7921C127.86 35.9296 127.906 36.0213 127.952 36.1588C127.998 36.2504 128.043 36.3879 128.043 36.4795C128.043 36.5712 128.089 36.7087 128.135 36.8004H128.181C128.227 36.7087 128.273 36.5254 128.318 36.3421C128.364 36.1588 128.456 35.9754 128.502 35.7921L129.281 33.684H129.877L128.273 37.9002C128.181 38.1294 128.089 38.3127 127.952 38.496C127.86 38.6793 127.723 38.771 127.539 38.8626C127.356 38.9543 127.173 39.0001 126.944 39.0001C126.852 39.0001 126.76 39.0001 126.669 39.0001C126.577 39.0001 126.531 38.9543 126.439 38.9543V38.496C126.485 38.496 126.531 38.496 126.623 38.5418C126.714 38.5418 126.76 38.5418 126.852 38.5418C126.989 38.5418 127.127 38.496 127.218 38.4502C127.31 38.4043 127.402 38.3127 127.493 38.221C127.585 38.1294 127.631 37.9919 127.677 37.8544L127.86 37.3503L126.394 33.6382Z"
        fill={descColor}
      />
    </svg>
  );
};

export default LogoLanding;
